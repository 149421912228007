/* --------------------------------------- */
/* STORE CONFIGURATION
/* --------------------------------------- */

const HOST = {
    local: 'http://localhost:3000',
    dev: 'https://pwa-omsbiofarma.testingnow.me',
    stage: 'https://pwa-oms-staging.digital-healthcare.id',
    prod: 'https://seller.medbiz.id',
};
// const HOST = {
//     local: 'http://localhost:3000',
//     dev: 'http://localhost:3000',
//     stage: 'http://localhost:3000',
//     prod: 'http://localhost:3000',
// };

/* Magento GraphQL Endpoint */
const graphqlEndpoint = {
    local: 'https://omsbiofarma.testingnow.me/graphql',
    dev: 'https://omsbiofarma.testingnow.me/graphql',
    stage: 'https://oms-staging.digital-healthcare.id/graphql',
    prod: 'https://oms-bo.medbiz.id/graphql',
};

/* Multiple GraphQL Endpoint */
const graphqlEndpointMultiple = {
    name: 'biofarma',
    token_local: 't7yniaqaq0vyql3t762ro7qqo5l3j3ys',
    token_dev: 't7yniaqaq0vyql3t762ro7qqo5l3j3ys',
    token_prod: 'uyzx3ifzmd21wmgdzhad4zqbdam96puv',
    token_stage: 'uyzx3ifzmd21wmgdzhad4zqbdam96puv',
    local: 'https://biofarma.testingnow.me/graphql',
    dev: 'https://biofarma.testingnow.me/graphql',
    stage: 'https://graphql-proxy-staging.digital-healthcare.id/graphql',
    prod: 'https://graphql-proxy.medbiz.id/graphql',
};

/* --------------------------------------- */
/* FEATURES CONFIGURATION
/* --------------------------------------- */

const installMessage = 'Get our free app.';
const appName = 'Swift APP';

/* Social Sharing */
const shareIcon = {
    facebook: true,
    twitter: true,
    line: true,
    email: true,
    telegram: true,
    pinterest: false,
    linkedin: false,
};

/* Password Validator */
const passwordStrength = {
    minValue: 8,
    maxValue: 20,
    numberOfRequiredClass: 3, // Lowercase + Uppercse + Dgits + spesial caracter = 4
};

/* Translation */
const translation = {
    defaultLanguage: 'en', // just change to your default language
    languages: ['en', 'id'], // array code language what you want
    // language label code
    languagesLabel: {
        en: 'English',
        id: 'Indonesia',
    },
};

/* Google Tag Manager
 * before enable this configuration, firstly you need to import the gtm tags json.
 * gtm tags json need to be exported from Magento admin in Welpixel GTM configuration.
 * adjust the tag name if you want before import into GTM dashboard setting.
 * as reference you can find sample gtm tags in folder "sample/gtm" folder
 * NOTE: this GTM functionality includes connecting to GA via GTM tag.
 */
const GTM = {
    enable: false,
    gtmId: {
        local: '', // sample: GTM-N76V8KQ
        dev: '', // sample: GTM-N76V8KQ
        stage: '', // sample: GTM-N76V8KQ
        prod: '', // sample: GTM-N76V8KQ
    },
};

/* Recapthca Configuration */
const recaptcha = {
    enable: false,
    siteKey: {
        local: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        dev: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        stage: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        prod: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
    },
    serverKey: {
        local: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        dev: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        stage: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        prod: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
    },
};

const sentry = {
    enabled: false,
    enableMode: 'production',
    dsn: {
        local: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        dev: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        stage: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
        prod: 'https://9700d1051b5b4e13a450411af92303e2@o451158.ingest.sentry.io/5436645',
    },
};

/* Loader */
const loaderImage = '/assets/img/loader.svg';

/* --------------------------------------- */
/* LOCAD DATA CACHE & COKIES
/* --------------------------------------- */

const expiredCookies = 6;
const storeConfigNameCookie = 'storeConfig';
const nameCartId = 'nci';
const custDataNameCookie = 'cdt';
const nameCheckoutCookie = 'ccdt';
const nameGlobalCookie = 'spwa';
const nameToken = 'sk';
const expiredToken = new Date(Date.now() + 1000 * 60 * 60);
const expiredDefault = 365;
const localResolverKey = 'resolver';

const features = {
    ssrCache: false,
    facebookMetaId: {
        enabled: false,
        app_id: '', // if enabled add fb app id here. e.g. 3080154482073095
    },
    vesMenu: {
        enabled: true,
    },
    customInstallApp: {
        enabled: true,
    },
    chatSystem: {
        enabled: true,
        graphqlEndpoint: {
            local: 'https://chat.biofarma-p3.testingnow.me/graphql',
            dev: 'https://medbiz-chat-dev.digital-healthcare.id/graphql',
            stage: 'https://medbiz-chat-staging.digital-healthcare.id/graphql',
            prod: 'https://chat.medbiz.id/graphql',
        },
        graphqlContext: 'chatSystem',
        config: {
            local: {
                apiKey: 'AIzaSyA32GwsjPf-NFDW1gscsnvTQWHL_wNiIhg',
                authDomain: 'biofarma-dev-ce93a.firebaseapp.com',
                projectId: 'biofarma-dev-ce93a',
                storageBucket: 'biofarma-dev-ce93a.appspot.com',
                messagingSenderId: '437466744228',
                appId: '1:437466744228:web:231b0b55f592fe21da2d31',
                measurementId: 'G-PNKVNNCPHE',
            },
            dev: {
                apiKey: 'AIzaSyA32GwsjPf-NFDW1gscsnvTQWHL_wNiIhg',
                authDomain: 'biofarma-dev-ce93a.firebaseapp.com',
                projectId: 'biofarma-dev-ce93a',
                storageBucket: 'biofarma-dev-ce93a.appspot.com',
                messagingSenderId: '437466744228',
                appId: '1:437466744228:web:231b0b55f592fe21da2d31',
                measurementId: 'G-PNKVNNCPHE',
            },
            stage: {
                apiKey: 'AIzaSyDyKn09KAz8872LpArYZ7_WFrqh8ktOw6Y',
                authDomain: 'bf-smartpbf-dgt-qa.firebaseapp.com',
                projectId: 'bf-smartpbf-dgt-qa',
                storageBucket: 'bf-smartpbf-dgt-qa.appspot.com',
                messagingSenderId: '1003503814980',
                appId: '1:1003503814980:web:c25391cee5280b464eaf11',
                measurementId: 'G-C7L1R614KW',
            },
            prod: {
                apiKey: 'AIzaSyCvGbSVAFZh4uFc7NwKwjcCzcLh21Q4Nlk',
                authDomain: 'bf-smartpbf-dgt-prod.firebaseapp.com',
                projectId: 'bf-smartpbf-dgt-prod',
                storageBucket: 'bf-smartpbf-dgt-prod.appspot.com',
                messagingSenderId: '770384439546',
                appId: '1:770384439546:web:29a3a4ef9cfa3248f74143',
                measurementId: 'G-ER0F6R7GWQ',
            },
        },
    },
    pushNotification: {
        enabled: false,
        config: {
            apiKey: 'AIzaSyBwAPEXdjKf84q-T7tUxVJBcOJJ8hzrXTI',
            authDomain: 'swift-pwa.firebaseapp.com',
            databaseURL: 'https://swift-pwa.firebaseio.com',
            projectId: 'swift-pwa',
            storageBucket: 'swift-pwa.appspot.com',
            messagingSenderId: '1029426161575',
            appId: '1:1029426161575:web:2c57e3f74cb00e0132f882',
            measurementId: 'G-VSRV1DJVSQ',
        },
    },
};

const nossrCache = ['/graphql'];

const debuging = {
    originalError: false,
};

module.exports = {
    sentry,
    debuging,
    GTM,
    HOST,
    graphqlEndpoint,
    graphqlEndpointMultiple,
    shareIcon,
    passwordStrength,
    translation,
    expiredCookies,
    storeConfigNameCookie,
    nameCartId,
    nameToken,
    expiredToken,
    expiredDefault,
    loaderImage,
    custDataNameCookie,
    nameCheckoutCookie,
    nameGlobalCookie,
    features,
    nossrCache,
    recaptcha,
    installMessage,
    appName,
    localResolverKey,
};